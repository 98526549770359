// import { getReferringDomainFullURL } from "../utils/URLUtils";

export const env = process.env.REACT_APP_ENV;
export const api = process.env.REACT_APP_API_ENDPOINT;
export const assetsURL = process.env.REACT_APP_ASSETS_URL;
export const messengerAppId = process.env.REACT_APP_MESSENGER_APP_ID;
export const referralLink = process.env.REACT_APP_REFERRAL_LINK;
export const defaultConsentText =
  "By clicking the button below, you give your consent to be called on behalf of schools that match your profile, at the telephone number(s) provided with automated technology, including your wireless number if provided. Consent is not required to enroll in these schools.";
export const defaultConsentTextV2 = `By checking this box, and providing my contact information, I am providing my electronic signature and consent to be contacted by the following selected school(s) <span data-tf-element-role='contact-method'> by email or telephone, including by text message </span> regarding educational services at the phone number above.  I acknowledge these calls may be generated using an automated dialing system. I understand that I am under no obligation to provide consent to purchase any goods or services. Message and data rates may apply.`;


export const getConsentTextForMatches = (schoolNames = "", referringDomain) => {
  const websiteName =
    schoolNames.length > 0
      ? `; ${websiteNames[referringDomain]}`
      : websiteNames[referringDomain];
  return `By checking this box, and providing my contact information, I am giving ${schoolNames}${websiteName} consent to contact me regarding educational services by email, phone, and/or text messages, including my mobile phone if provided, using an automated dialing system. I understand that I am under no obligation to provide consent to purchase any goods or services. Message and data rates may apply.`;
};

export const searchingSchoolMessage = [
  "Please wait while we search for schools...",
  "Almost there! Finding the best matches for you.",
  "Thanks for your patience—locating schools tailored to your needs!",
  "We’re gathering options that best fit your profile!",
  "Hang tight! We’re almost done finding your matches.",
  "Just a few more moments to get the best results!"
];
export const submittingMatchesMessage =
  "Please wait while we are submitting your selected matches";

export const birthAndGradYearDiff = 18;
export const maxBirthAndGradYearDiff = 20;

export const categories = {
  1: "Healthcare",
  2: "Teaching",
  3: "Nursing",
  4: "Criminal Justice · Legal Studies",
  5: "Business · Marketing",
  6: "Computers · IT",
  7: "Automotive · Trades",
  8: "Design · Creative",
  9: "Psychology",
  10: "Massage Therapy",
  11: "Religious Studies",
  12: "Culinary · Hospitality"
};

export const categoryMap = {
  1: [1, 3, 9],
  2: [2, 11],
  3: [3],
  4: [4],
  5: [5],
  6: [6, 8],
  7: [7, 12],
  8: [8],
  9: [9],
  10: [10],
  11: [11],
  12: [12],
  98: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  99: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
};

export const fourOFourErrorMessage = "Sorry! You are not supposed to be here.";
export const invalidZipErrorMessage = "Sorry! The given zip code is not valid.";
export const CCPAConsentText =
  "We will collect your contact information to respond to your requests for information and provide you with educational matching services. As a result, you may be matched to one or more sponsored schools from which we receive compensation. Compensation may impact where Sponsored Schools appear on our websites. We use cookies and other tracking technologies to collect your personal information in order to gauge online activity on our website, measure the effectiveness of online services, applications, and tools. For more information, see our privacy policy.";

export const USStates = [
  { text: "Alabama", value: "AL" },
  {
    text: "Alaska",
    value: "AK"
  },
  {
    text: "American Samoa",
    value: "AS"
  },
  {
    text: "Arizona",
    value: "AZ"
  },
  {
    text: "Arkansas",
    value: "AR"
  },
  {
    text: "California",
    value: "CA"
  },
  {
    text: "Colorado",
    value: "CO"
  },
  {
    text: "Connecticut",
    value: "CT"
  },
  {
    text: "Delaware",
    value: "DE"
  },
  {
    text: "District Of Columbia",
    value: "DC"
  },
  {
    text: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    text: "Florida",
    value: "FL"
  },
  {
    text: "Georgia",
    value: "GA"
  },
  {
    text: "Guam",
    value: "GU"
  },
  {
    text: "Hawaii",
    value: "HI"
  },
  {
    text: "Idaho",
    value: "ID"
  },
  {
    text: "Illinois",
    value: "IL"
  },
  {
    text: "Indiana",
    value: "IN"
  },
  {
    text: "Iowa",
    value: "IA"
  },
  {
    text: "Kansas",
    value: "KS"
  },
  {
    text: "Kentucky",
    value: "KY"
  },
  {
    text: "Louisiana",
    value: "LA"
  },
  {
    text: "Maine",
    value: "ME"
  },
  {
    text: "Marshall Islands",
    value: "MH"
  },
  {
    text: "Maryland",
    value: "MD"
  },
  {
    text: "Massachusetts",
    value: "MA"
  },
  {
    text: "Michigan",
    value: "MI"
  },
  {
    text: "Minnesota",
    value: "MN"
  },
  {
    text: "Mississippi",
    value: "MS"
  },
  {
    text: "Missouri",
    value: "MO"
  },
  {
    text: "Montana",
    value: "MT"
  },
  {
    text: "Nebraska",
    value: "NE"
  },
  {
    text: "Nevada",
    value: "NV"
  },
  {
    text: "New Hampshire",
    value: "NH"
  },
  {
    text: "New Jersey",
    value: "NJ"
  },
  {
    text: "New Mexico",
    value: "NM"
  },
  {
    text: "New York",
    value: "NY"
  },
  {
    text: "North Carolina",
    value: "NC"
  },
  {
    text: "North Dakota",
    value: "ND"
  },
  {
    text: "Northern Mariana Islands",
    value: "MP"
  },
  {
    text: "Ohio",
    value: "OH"
  },
  {
    text: "Oklahoma",
    value: "OK"
  },
  {
    text: "Oregon",
    value: "OR"
  },
  {
    text: "Palau",
    value: "PW"
  },
  {
    text: "Pennsylvania",
    value: "PA"
  },
  {
    text: "Puerto Rico",
    value: "PR"
  },
  {
    text: "Rhode Island",
    value: "RI"
  },
  {
    text: "South Carolina",
    value: "SC"
  },
  {
    text: "South Dakota",
    value: "SD"
  },
  {
    text: "Tennessee",
    value: "TN"
  },
  {
    text: "Texas",
    value: "TX"
  },
  {
    text: "Utah",
    value: "UT"
  },
  {
    text: "Vermont",
    value: "VT"
  },
  {
    text: "Virgin Islands",
    value: "VI"
  },
  {
    text: "Virginia",
    value: "VA"
  },
  {
    text: "Washington",
    value: "WA"
  },
  {
    text: "West Virginia",
    value: "WV"
  },
  {
    text: "Wisconsin",
    value: "WI"
  },
  {
    text: "Wyoming",
    value: "WY"
  }
];

export const searchMatchDelayTime = 600;
//const currentUrl = window.location.href;
//const referrerUrl = getReferringDomainFullURL(currentUrl);
export const dreamBoundSchoolLogo = "/assets/images/school-logos.gif";
export const eddyClicksMatches = [
  // {
  //   schoolName: "Fill Out the Form to Get Matched!",
  //   imageURL: "/assets/images/eddy-school.png",
  //   description:
  //     "Our purpose is to help match you to schools and degree programs that meet your education goals and objectives. We'll help you take the first step - for free! ClassesUSA.com/FindYourCollege",
  //   url: "https://www.classesusa.com/get-matched-4?trackid=A6803425-7CAE-4D90-81E4-374A093116A1&v=cd&c=cusa_cd_general",
  //   schoolTitle: "ClassesUSA",
  //   schoolSearchId: "eddyClassesUSA",
  //   isStatic: true,
  //   point: 4
  // }
];

export const defaultClickoutsMatches = {
  count: 5,
  schools: [
    ...eddyClicksMatches,
    {
      schoolName: "Ultimate Medical Academy",
      imageURL: "/assets/images/uma_md2.png",
      description:
        "Ultimate Medical Academy is a nonprofit healthcare career school committed to helping you succeed. We offer flexible online courses that prepare you for a healthcare career on your schedule, and our advisors are here to support you throughout your education and beyond.",
      url: "https://swidget.matcheduschools.com/ULTIMATEMEDONL/"
    }
  ]
};

export const defaultArticles = {
  articles: [
    {
      articleTitle: "Do Online Students Make Better Remote Workers",
      imageURL: "/assets/images/online-college.jpg",
      description:
        "What kind of learning builds discipline, tech skills, and sharp communication—all for remote jobs? The answer holds the key to standing out. Ready to uncover it? Dive into this article!",
      url: "https://supportivecolleges.com/online-students-do-better-remote-jobs/"
    },
    {
      articleTitle: "How To Use NSSE Scores To Evaluate College",
      imageURL: "/assets/images/nsse.jpg",
      description:
        "What’s the secret to unlocking student success? Since 2000, a survey has been revealing the keys to impactful learning. Curious? Discover the answers here!",
      url: "https://supportivecolleges.com/nsse-student-success/"
    }
  ]
};
export const selectAllImage = "/assets/images/select-all.jpeg";
export const siteNames = {
  intro2healthcare: "Intro 2 Healthcare",
  supportivecolleges: "Supportive Colleges",
  intro2trades: "Intro 2 Trades"
};
export const incompleteWidgetId = "56419007-79d0-4d70-9d28-36ae52134cf4";

export const pixelEventName = {
  pageView: "PageView",
  lead: "Lead",
  completeRegistration: "CompleteRegistration",
  placedLead: "PlacedLead"
};

export const snapchatPixelEventName = {
  pageView: "PAGE_VIEW",
  signup: "SIGN_UP",
  purchase: "PURCHASE"
};

export const fbPixelsIds = {
  mycollegelaptop: process.env.REACT_APP_FB_PIXEL_ID_FOR_MY_COLLEGE_LAPTOP,
  supportivecolleges: process.env.REACT_APP_FB_PIXEL_ID_FOR_SUPPORTIVE_COLLEGES
};

export const providers = {
  maximizer: "EDU MAXIMIZER",
  zimia: "ZIMIA",
  qs: "QUIN STREET",
  prospex: "PROSPEX",
  eduDynamics: "EDU DYNAMICS",
  dms: "DMS",
  dmsCEC: "DMS CEC",
  neutron: "NEUTRON"
};

export const websiteNames = {
  "intro2healthcare.com": "Intro2Healthcare",
  "supportivecolleges.com": "Supportive Colleges",
  "intro2trades.com": "Intro2Trade"
};

export const textColorCodes = {
  "intro2healthcare.com": "#006ba6",
  "supportivecolleges.com": "#FF7833"
};

export const indicatorColorCodes = {
  "intro2healthcare.com": "#006ba6",
  "supportivecolleges.com": "#FF7833"
};
