import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Form, withFormik } from "formik";
import {
  Button,
  Typography,
  Box,
  
} from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import {
  FormBodyContainer,
  FormField,
  FormHeaderContainer,
  PreviousButton,
  
  CustomButton,
  //CCPAText,
  TCPAText,
  
} from "../../components";
import ChildQuestionField from "./ChildQuestionField";
import {
  validateRequiredFiled,
  validatePhoneNumber,
  hasFieldError,
  hasEmptyField
} from "../../../utils/validator";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  defaultConsentText,
  getConsentTextForMatches,
  birthAndGradYearDiff,
  maxBirthAndGradYearDiff,
  //CCPAConsentText,
  assetsURL,
  incompleteWidgetId,
  pixelEventName,
  snapchatPixelEventName,
  defaultConsentTextV2,
  //
} from "../../../config";
import updateStepThreeLeadData from "../../../helpers/updateStepThreeLeadData";
import isMobileBrowser from "../../../helpers/isMobileBrowser";
import { questionActions } from "../../../state/question";
import { leadActions } from "../../../state/lead";
import { matchActions } from "../../../state/match";
import { trackActions } from "../../../state/track";
import styled from "styled-components";
import ExclusiveTCPAText from "../../components/ExclusiveTCPAText";
import TcpaCard from "../../components/TcpaCard";

const HiddenImage = styled.img`
  display: none;
`;

const uncheckedWidgetsId = [
  "50bace3d-1e98-4ddc-b888-ddc1ee3bbc2f",
  "40568c6d-a25b-4795-9fec-cb2dcb435784"
];

const schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Letters only please")
    .min(2, "Please enter valid name")
    .required("This field is required.")
    .trim(),
  lastName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Letters only please")
    .min(2, "Please enter valid name")
    .required("This field is required.")
    .when("firstName", {
      is: val => val && val.length > 0,
      then: Yup.string().notOneOf(
        [Yup.ref("firstName")],
        "FirstName and LastName can not be same."
      )
    })
    .trim(),
  email: Yup.string().email("Please enter a valid email address."),
  phone: Yup.string()
    .required("Please enter a valid phone number")
    .min(10, "Please enter at least 10 characters.")
    .matches(
      /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
      "Please enter valid phone number"
    )
});

class StepThreeForm extends Component {
  state = {
    bestTimeToContact: "",
    isAllQuestionValidated: false,
    isTcpaDisclosureChecked: false,
    showNotFoundMessage: false,
    isConversionEventDone: false,
    isCCPAConcentSent: false,
    isCCPAConcentChecked: true,
    leadCountEventSent: false,
    isTCPAConcentSent: false,
    isCCPACheckedByWidgetsId:
      uncheckedWidgetsId.includes(this.props.widgetData.id) &&
      this.props.locationData.state === "CA"
        ? false
        : true,
    referringDomain: "",
    
    isDialogBoxAlreadyOpen: false,
    oneToOneTcpaStage: false,
    selectedSchoolIndex: -1,
    oneToOneTcpaSchools: [],
    sharedSchools: [],
    userSubmittedSchool: [],
    exclusiveLead: false,
    nonExclusiveLead: false
  };

  componentDidMount() {
    const { trackPixel, leadData, trackSnapchatPixel } = this.props;
    window.scrollTo(0, 0);
    const url = new URL(window.location.href);
    const referringWebsite = url.searchParams.get("referringDomain");
    this.setState({ referringDomain: referringWebsite });
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3",
      action: "Load"
    });

    trackPixel({
      step: "3",
      eventName: pixelEventName.pageView,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.pageView,
      eventTag: "Lead Conversion",
      leadData
    });
  }

  componentDidUpdate() {
    const {
      bestTimeToContact,
      isAllQuestionValidated,
      isCCPAConcentSent,
      leadCountEventSent,
      isTCPAConcentSent,
      exclusiveLead,
      oneToOneTcpaSchools
    } = this.state;

    const {
      values,
      updateLead,
      completeLead,
      leadData,
      currentStep,
      questions,
      searchMatchesV2,
      locationData,
      formData,
      isSearchingMatches,
      widgetData,
      allMatches,
      searchMatchesDone,
      isSubmittingMatches,
      submitMatchesDone,
      submittedSchools
    } = this.props;

    if (
      values.bestTimeToContact &&
      values.bestTimeToContact !== bestTimeToContact
    ) {
      this.setState({
        bestTimeToContact: values.bestTimeToContact,
        leadCountEventSent: false
      });

      let data = {
        questionName: "bestTimeToContact",
        questionValue: values.bestTimeToContact,
        step: currentStep
      };

      updateLead(leadData.id, data);

      if (locationData.state === "CA" && !isCCPAConcentSent) {
        const CCPAConcentData = {
          questionName: "hasCCPAConsent",
          questionValue: "Y",
          step: currentStep
        };
        updateLead(leadData.id, CCPAConcentData);
        this.setState({ isCCPAConcentSent: true });
      }

      if (!hasEmptyField(questions, values) && !isSearchingMatches) {
        this.setState({ isTcpaDisclosureChecked: false });

        const trustedFormField =
          document.getElementsByName("xxTrustedFormToken")[0];
        const trustedFormCertURLField = document.getElementsByName(
          "xxTrustedFormCertUrl"
        )[0];
        const trustedFormPingURLField = document.getElementsByName(
          "xxTrustedFormPingUrl"
        )[0];

        const trustedFormToken = trustedFormField ? trustedFormField.value : "";
        const trustedFormCertURL = trustedFormCertURLField
          ? trustedFormCertURLField.value
          : "";
        const trustedFormPingURL = trustedFormPingURLField
          ? trustedFormPingURLField.value
          : "";
        let searchData = {
          ...formData,
          ...values,
          trustedFormToken,
          trustedFormCertURL,
          trustedFormPingURL,
          leadId: leadData.id,
          affiliateCampaignId: widgetData.maximizerAffiliateCampaignId,
          universalLeadIdToken: leadData.universalLeadId,
          categoryId: leadData.categoryId,
          referralURL: leadData.referralURL,
          schoolType: leadData.schoolType || "Both",
          phone: values.phone.replace(/[^\d]/g, ""),
          city: locationData.city,
          state: locationData.state,
          zip: locationData.zip || locationData.zipCode,
          hasRNLicense: formData.hasRNLicense || "N",
          hasTeachingLicense: formData.hasTeachingLicense || "N",
          USMilitaryAffiliation:
            formData.USMilitaryAffiliation || "No U.S. Military Affiliation",
          widgetId: widgetData.id,
          suppressProviders: widgetData.suppressProviders || {},
          isProspexAllMatch: widgetData.isProspexAllMatch || false
        };

        if (
          Number(formData.gradYear) - Number(formData.birthYear) >
          maxBirthAndGradYearDiff
        ) {
          searchData.gradYear =
            Number(formData.birthYear) + birthAndGradYearDiff;
        }

        if (schema.isValidSync(values) && !validatePhoneNumber(values.phone)) {
          let consentData = {
            consentText: defaultConsentText,
            hasConsent: true
          };
          if (!isAllQuestionValidated) {
            updateStepThreeLeadData({
              leadData,
              updateLead,
              values,
              currentStep
            });
            completeLead(leadData.id, consentData);
            this.setState({ isAllQuestionValidated: true });
          }

          createGTMEvent({
            event: "Step Conversion",
            label: "Step 3 BestTimeToContact Dropdown",
            action: "Click"
          });
          searchMatchesV2(schema.cast({ ...searchData }));
          // searchClickOutsMatches({
          //   siteUrl: leadData.referralURL,
          //   zipCode: locationData.zip || locationData.zipCode
          // });
          this.setState({ showNotFoundMessage: true });
        }
      }
    }

    if (!this.state.isConversionEventDone) {
      const { isSubmittingMatches, submitMatchesDone } = this.props;

      if (!isSubmittingMatches && submitMatchesDone) {
        createGTMEvent({
          event: "Conversion"
        });
        this.setState({ isConversionEventDone: true });
      }
    }

    if (!isSearchingMatches && searchMatchesDone && !leadCountEventSent) {
      createGTMEvent({
        event: "Match Search",
        label: leadData.id,
        action: "Click",
        category: "DropDownMatchFound",
        value: allMatches.count
      });
      this.setState({ leadCountEventSent: true });
    }

    if (allMatches.count > 0 && !isTCPAConcentSent) {
      const TCPAConsentData = {
        questionName: "hasTCPAConsent",
        questionValue: "N",
        step: currentStep
      };

      const TCPAConsentText = {
        questionName: "TCPAConsentText",
        questionValue: getConsentTextForMatches(
          allMatches && allMatches.count > 0
            ? this.getSchoolNames(allMatches.matches)
            : ""
        ),
        step: currentStep
      };

      updateLead(leadData.id, TCPAConsentData);
      updateLead(leadData.id, TCPAConsentText);
      this.setState({ isTCPAConcentSent: true });
      let oneToOneTcpaSchools = [],
        sharedSchools = [];

      for (let school of allMatches.matches) {
        if (school.exclusiveLead) {
          sharedSchools.push(school);
          this.setState({ exclusiveLead: true });
        } else {
          oneToOneTcpaSchools.push(school);
        }
      }
      if (sharedSchools.length === 0 && oneToOneTcpaSchools.length !== 0) {
        this.setState({
          selectedSchoolIndex: 0,
          oneToOneTcpaStage: true,
          nonExclusiveLead: true
        });
      }
      this.setState({
        oneToOneTcpaSchools: oneToOneTcpaSchools,
        sharedSchools: sharedSchools
      });
    }
    if (
      !isSubmittingMatches &&
      submitMatchesDone &&
      exclusiveLead &&
      submittedSchools?.exclusiveLeadSubmission !== "success" &&
      oneToOneTcpaSchools.length > 0
    ) {
      this.setState({
        exclusiveLead: false,
        nonExclusiveLead: true
      });
    }
  }

  onPreviousStepButtonClicked = data => {
    const { goToPreviousStep, values, errors, touched } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Previous Button",
      action: "Click"
    });

    goToPreviousStep(values, !hasFieldError(errors, touched));
  };

  nextSelectedSchool = () => {
    const { selectedSchoolIndex, oneToOneTcpaSchools, userSubmittedSchool } =
      this.state;
    const { setSelectedSchoolOptions, values, goToNextStep } = this.props;
    if (selectedSchoolIndex === oneToOneTcpaSchools.length - 1) {
      setSelectedSchoolOptions([...userSubmittedSchool]);
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 Lead",
        value: userSubmittedSchool.length || 0,
        action: "Click"
      });
      goToNextStep(values);
    } else {
      this.setState({ selectedSchoolIndex: selectedSchoolIndex + 1 });
    }
  };

  giveTcpaPermission = event => {
    const { setSelectedSchoolOptions } = this.props;
    const { selectedSchoolIndex, oneToOneTcpaSchools } = this.state;
    if (event.target.checked) {
      this.setState({ isTcpaDisclosureChecked: true });
      setSelectedSchoolOptions([
        oneToOneTcpaSchools[selectedSchoolIndex].schoolName
      ]);
      // Need to add analytics tracking for TCPA events
    } else {
      this.setState({ isTcpaDisclosureChecked: false });
      setSelectedSchoolOptions([]);
      // Need to add analytics tracking for TCPA events
    }
  };

  submitLeadOnNextButtonClicked = async () => {
    const {
      goToNextStep,
      values,
      allMatches,
      submitMatches,
      trackPixel,
      leadData,
      trackSnapchatPixel,
      selectedSchoolOptions,
      setSelectedSchoolOptions
    } = this.props;
    const {
      oneToOneTcpaSchools,
      selectedSchoolIndex,
      oneToOneTcpaStage,
      userSubmittedSchool,
      exclusiveLead,
      nonExclusiveLead
    } = this.state;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Next Button",
      action: "Click"
    });

    if (!isMobileBrowser()) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 Next Button Desktop",
        action: "Click"
      });
    } else {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 Next Button Mobile",
        action: "Click"
      });
    }
    if (allMatches && allMatches.count > 0) {
      await submitMatches({
        reference: allMatches.reference,
        leadId: leadData.id,
        selectedSchoolOptions: selectedSchoolOptions,
        exclusiveLead: exclusiveLead
      });
      const updatedUserSubmittedSchool = userSubmittedSchool;

      selectedSchoolOptions.forEach(school => {
        updatedUserSubmittedSchool.push(school);
      });

      this.setState({ userSubmittedSchool: [...updatedUserSubmittedSchool] });
    }

    trackPixel({
      step: "3",
      eventName: pixelEventName.lead,
      leadData
    });
    trackPixel({
      step: "3",
      eventName: pixelEventName.placedLead,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.purchase,
      eventTag: "Lead Conversion",
      leadData
    });

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Lead",
      action: "Click"
    });

    let updatedoneToOneTcpaSchools = oneToOneTcpaSchools;

    if (oneToOneTcpaStage === false) {
      this.setState({ oneToOneTcpaStage: true, selectedSchoolIndex: 0 });
    }
    setSelectedSchoolOptions([]);
    this.setState({ isTcpaDisclosureChecked: false });
    const { submittedSchools } = this.props;
    if (
      updatedoneToOneTcpaSchools.length === 0 ||
      updatedoneToOneTcpaSchools.length - 1 === selectedSchoolIndex ||
      (exclusiveLead &&
        !nonExclusiveLead &&
        submittedSchools?.exclusiveLeadSubmission === "success")
    ) {
      const { userSubmittedSchool } = this.state;
      setSelectedSchoolOptions([...userSubmittedSchool]);
      goToNextStep(values);
    } else {
      this.nextSelectedSchool();
    }
  };

  onSearchStageTwoButtonClicked = data => {
    const {
      goToStageTwoSearch,
      // resetMatchSearch,
      values,
      leadData,
      trackPixel,
      trackSnapchatPixel
    } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 View Your Matches Now Button",
      action: "Click"
    });
    trackPixel({
      step: "3",
      eventName: pixelEventName.lead,
      leadData
    });
    trackSnapchatPixel({
      step: "3",
      eventName: snapchatPixelEventName.purchase,
      eventTag: "Lead Conversion",
      leadData
    });

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 3 Lead",
      action: "Click"
    });
    // resetMatchSearch();
    goToStageTwoSearch(values);
  };

  onPhoneNumberChange = ({ target: { name, value } }) => {
    if (value.length < 15) {
      const { setFieldValue } = this.props;

      let phone = value.replace(/[^\d]/g, "");

      if (phone.length === 10) {
        phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      setFieldValue(name, phone);
    }
  };

  handleOnBlur = ({ target: { name } }, questionName) => {
    const {
      setFieldTouched,
      values,
      updateLead,
      leadData,
      currentStep,
      setCompletedSteps,
      errors,
      touched,
      stepCompleted,
      setFieldValue
      // validateEmail
    } = this.props;

    let value = values[name];

    if (value && name === "phone") {
      value = value.replace(/[^\d]/g, "");
    }

    if (value && name === "email" && window && window.dataLayer) {
      window.dataLayer.push({ user_email: value });
    }
    let data = {
      questionName,
      questionValue: value,
      step: currentStep
    };

    setFieldTouched(name, true);
    if (leadData.id && values[name] && !Object.keys(errors).includes(name)) {
      updateLead(leadData.id, data);
    }

    if (!values[name]) {
      let steps = [...stepCompleted];
      let idx = stepCompleted.findIndex(s => s === currentStep);
      if (idx > -1) {
        steps.splice(idx, 1);
      }
      setCompletedSteps(steps);
    } else {
      let steps = [...stepCompleted];
      if (!hasFieldError(errors, touched) && !steps.includes(currentStep)) {
        setCompletedSteps([...steps, currentStep]);
      }
    }

    if (name !== "bestTimeToContact") {
      setFieldValue("bestTimeToContact", "");
      setFieldTouched("bestTimeToContact", false);
      this.setState({ bestTimeToContact: "", showNotFoundMessage: false });
    }
  };

  onTcpaDisclosureClicked = ({ target: { name, value, checked } }) => {
    const {
      // submitMatches,
      allMatches,
      leadData,
      updateLead,
      currentStep
      // trackPixel
    } = this.props;

    const { isTcpaDisclosureChecked } = this.state;

    if (checked) {
      this.props.setSelectedSchoolOptions(
        this.props.allMatches.matches.map(school => school.schoolName)
      );
    } else {
      this.props.setSelectedSchoolOptions([]);
    }
    if (checked && !isTcpaDisclosureChecked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 TCPA Checkbox",
        action: "Click"
      });

      createGTMEvent({
        event: "Match Search",
        label: leadData.id,
        action: "Click",
        category: "TCPAClickedOrganic",
        value: allMatches.count
      });

      const data = {
        questionName: "hasTCPAConsent",
        questionValue: "Y",
        step: currentStep
      };

      updateLead(leadData.id, data);
      if (isMobileBrowser()) {
        createGTMEvent({
          event: "Step Conversion",
          label: "Step 3 TCPA Checkbox Mobile",
          action: "Click"
        });
      } else {
        createGTMEvent({
          event: "Step Conversion",
          label: "Step 3 TCPA Checkbox Desktop",
          action: "Click"
        });
      }
      this.setState({ isTcpaDisclosureChecked: true });
    }

    if (!checked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 3 TCPA Checkbox Unchecked",
        action: "Click"
      });
    }
  };

  onCCPADisclosureClicked = () => {
    if (uncheckedWidgetsId.includes(this.props.widgetData.id)) {
      this.setState({
        isCCPACheckedByWidgetsId: !this.state.isCCPACheckedByWidgetsId
      });
    } else {
      this.setState({
        isCCPAConcentChecked: !this.state.isCCPAConcentChecked
      });
    }
  };

  getSchoolNames = matches => {
    let uniqueSchoolsSet = new Set();
    for (let i = 0; i < matches.length; i++) {
      uniqueSchoolsSet.add(matches[i].schoolName);
    }
    let uniqueSchoolsArray = Array.from(uniqueSchoolsSet);
    let schools = "";
    for (let i = 0; i < uniqueSchoolsArray.length; i++) {
      schools += uniqueSchoolsArray[i];
      if (i !== uniqueSchoolsArray.length - 1) {
        schools += "; ";
      }
    }
    return schools;
  };

  onNextStepButtonClicked = () => {
    const { selectedSchoolOptions } = this.props;
    const { sharedSchools } = this.state;
    if (
      selectedSchoolOptions.length === sharedSchools.length ||
      this.state.isDialogBoxAlreadyOpen
    ) {
      this.submitLeadOnNextButtonClicked();
    } else {
      this.handleClickOpen();
    }
  };

  handleSelectAllClicked = event => {
    const { sharedSchools } = this.state;
    if (event.target.checked) {
      this.props.setSelectedSchoolOptions(
        sharedSchools.map(school => school.schoolName)
      );
      this.setState({ isTcpaDisclosureChecked: true });
      // Need to add analytics tracking for TCPA events
    } else {
      this.props.setSelectedSchoolOptions([]);
      this.setState({ isTcpaDisclosureChecked: false });
      // Need to add analytics tracking for TCPA events
    }
  };

  handleOptionClicked = event => {
    const option = event.target.value;
    const { selectedSchoolOptions, setSelectedSchoolOptions } = this.props;
    let updatedSelectedSchools;
    if (selectedSchoolOptions.includes(option)) {
      updatedSelectedSchools = selectedSchoolOptions.filter(o => o !== option);
    } else {
      updatedSelectedSchools = [...selectedSchoolOptions, option];
    }
    setSelectedSchoolOptions(updatedSelectedSchools);
    if (updatedSelectedSchools.length > 0) {
      this.setState({ isTcpaDisclosureChecked: true });
    } else {
      this.setState({ isTcpaDisclosureChecked: false });
    }
  };

  render() {
    const {
      questions,
      allQuestions,
      errors,
      touched,
      values,
      isSearchingMatches,
      searchMatchesDone,
      searchMatchesFailed,
      allMatches,
      //locationData,
      selectedSchoolOptions,
      widgetData
    } = this.props;

    const {
      isAllQuestionValidated,
      isTcpaDisclosureChecked,
      showNotFoundMessage,
      isCCPAConcentChecked,
      isCCPACheckedByWidgetsId,
      referringDomain,
    
      oneToOneTcpaStage,
      selectedSchoolIndex,
      oneToOneTcpaSchools,
      sharedSchools,
      exclusiveLead,
      nonExclusiveLead
    } = this.state;
    const isAllSelected = sharedSchools.length === selectedSchoolOptions.length;

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">
            {!oneToOneTcpaStage ? "Contact Information" : "Select Your Match"}
          </Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body" data-tf-element-role="offer">
          <Form>
            {questions.length &&
              !oneToOneTcpaStage &&
              questions.map((question, idx) => {
                switch (question.type) {
                  case "dropdown":
                    return !question.isDependent ? (
                      <Fragment key={idx}>
                        <FormField
                          name={`${question.metaKey}`}
                          label={question.label}
                          component="select"
                          validate={validateRequiredFiled}
                          touched={touched[`${question.metaKey}`]}
                          error={errors[`${question.metaKey}`]}
                          onBlur={e => {
                            this.handleOnBlur(e, question.metaKey);
                          }}
                          disabled={allMatches.count > 0 ? true : false}
                          id={
                            question.metaKey === "bestTimeToContact"
                              ? "best-time-to-contact"
                              : ""
                          }
                        >
                          <option value="">- Select -</option>
                          {question.options &&
                            question.options.length &&
                            question.options.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.text || option.name}
                              </option>
                            ))}
                        </FormField>

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                  disabled={allMatches.count > 0 ? true : false}
                                />
                              );
                            }

                            return "";
                          })}
                      </Fragment>
                    ) : (
                      ""
                    );
                  default:
                    return (
                      <Fragment key={idx}>
                        {question.metaKey !== "phone" && (
                          <FormField
                            type={
                              question.metaKey === "email" ? "email" : "text"
                            }
                            name={`${question.metaKey}`}
                            label={question.label}
                            placeholder={question.label}
                            validate={
                              question.metaKey === "phone"
                                ? validatePhoneNumber
                                : validateRequiredFiled
                            }
                            touched={touched[`${question.metaKey}`]}
                            error={errors[`${question.metaKey}`]}
                            onBlur={e => {
                              this.handleOnBlur(e, question.metaKey);
                            }}
                            disabled={allMatches.count > 0 ? true : false}
                            {...(question.metaKey === "email" && {
                              "data-tf-element-role": "consent-grantor-email"
                            })}
                            {...((question.metaKey === "firstName" ||
                              question.metaKey === "lastName") && {
                              "data-tf-element-role": "consent-grantor-name"
                            })}
                          />
                        )}

                        {question.metaKey === "phone" && (
                          <FormField
                            type="text"
                            name={`${question.metaKey}`}
                            label={question.label}
                            placeholder={question.label}
                            validate={
                              question.metaKey === "phone"
                                ? validatePhoneNumber
                                : validateRequiredFiled
                            }
                            touched={touched[`${question.metaKey}`]}
                            error={errors[`${question.metaKey}`]}
                            onBlur={e => {
                              this.handleOnBlur(e, question.metaKey);
                            }}
                            onChange={this.onPhoneNumberChange}
                            disabled={allMatches.count > 0 ? true : false}
                            data-tf-element-role="consent-grantor-phone"
                          />
                        )}

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                  disabled={allMatches.count > 0 ? true : false}
                                />
                              );
                            }
                            return "";
                          })}
                      </Fragment>
                    );
                }
              })}

            <Box mt={2}>
              {/* {locationData.state === "CA" && (
                <FormField
                  id="leadid_tcpa_disclosure_CA"
                  type="checkbox"
                  name="caTcpaDisclosure"
                  label={
                    <CCPAText
                      url={
                        window.referringDomain
                          ? `https://${window.referringDomain}/privacy-policy`
                          : "#"
                      }
                    />
                  }
                  value={CCPAConsentText}
                  checked={isCCPAConcentChecked && isCCPACheckedByWidgetsId}
                  onClick={e => {
                    this.onCCPADisclosureClicked(e);
                  }}
                  disabled={values.tcpaDisclosure}
                />
              )}
              {locationData.state === "CA" && !isCCPAConcentChecked && (
                <Box mt={2} pl={3}>
                  <Typography color="error">
                    Please check CCPA concent to proceed
                  </Typography>
                </Box>
              )} */}

              {!isSearchingMatches && searchMatchesDone && (
                <>
                  {allMatches && allMatches.count === 0 && (
                    <FormField
                      id="leadid_tcpa_disclosure"
                      type="checkbox"
                      name="tcpaDisclosure"
                      label={getConsentTextForMatches("", referringDomain)}
                      value={defaultConsentText}
                      onClick={e => {
                        this.onTcpaDisclosureClicked(e);
                      }}
                      disabled={
                        !isCCPAConcentChecked || !isCCPACheckedByWidgetsId
                      }
                      data-tf-element-role="consent-opt-in"
                      trustedFormLabel="consent-language"
                    />
                  )}

                  {sharedSchools &&
                    sharedSchools.length > 0 &&
                    !oneToOneTcpaStage && (
                      <>
                        <FormField
                          type="checkbox"
                          id="leadid_tcpa_disclosure"
                          name="tcpaDisclosure"
                          label={
                            sharedSchools.length === 1 ? (
                              <ExclusiveTCPAText
                                tcpaConcentText={
                                  sharedSchools[0].tcpaConcentText
                                }
                              />
                            ) : (
                              <TCPAText />
                            )
                          }
                          onClick={
                            sharedSchools[0].exclusiveLead ||
                            sharedSchools.length === 1
                              ? this.handleSelectAllClicked
                              : undefined
                          }
                          value={
                            sharedSchools[0].exclusiveLead
                              ? sharedSchools[0].tcpaConcentText
                              : defaultConsentTextV2
                          }
                          data-tf-element-role="consent-opt-in"
                          trustedFormLabel="consent-language"
                        />

                        {sharedSchools &&
                          !sharedSchools[0].exclusiveLead &&
                          sharedSchools.length > 1 && (
                            <>
                              <Typography>
                                Optimize Your Success. Pick all{" "}
                                {sharedSchools.length} Schools.
                              </Typography>

                              <FormField
                                id="selectAll"
                                type="checkbox"
                                name="selectAll"
                                label="SELECT ALL"
                                checked={isAllSelected}
                                onClick={this.handleSelectAllClicked}
                                value="selectAll"
                                disabled={
                                  !isCCPAConcentChecked ||
                                  !isCCPACheckedByWidgetsId
                                }
                                data-tf-element-role="consent-opted-advertiser-input-SELECT-ALL"
                                trustedFormLabel="consent-opted-advertiser-name-SELECT-ALL"
                              />
                              {sharedSchools.map((school, index) => (
                                <Fragment key={index}>
                                  <FormField
                                    id={`${school.schoolName.replace(
                                      /\s/g,
                                      "_"
                                    )}_${index + 1}`}
                                    type="checkbox"
                                    name={`${school.schoolName.replace(
                                      /\s/g,
                                      "_"
                                    )}_${index + 1}`}
                                    checked={selectedSchoolOptions.includes(
                                      school.schoolName
                                    )}
                                    onClick={this.handleOptionClicked}
                                    value={school.schoolName}
                                    key={school.schoolId}
                                    label={school.schoolName}
                                    data-tf-element-role={`consent-opted-advertiser-input-${school.schoolName.replace(
                                      /\s/g,
                                      "-"
                                    )}`}
                                    trustedFormLabel={`consent-opted-advertiser-name-${school.schoolName.replace(
                                      /\s/g,
                                      "-"
                                    )}`}
                                  />
                                </Fragment>
                              ))}
                            </>
                          )}
                      </>
                    )}
                </>
              )}

              {oneToOneTcpaSchools &&
                oneToOneTcpaSchools.length > 0 &&
                oneToOneTcpaStage &&
                nonExclusiveLead &&
                !exclusiveLead && (
                  <TcpaCard
                    school={oneToOneTcpaSchools[selectedSchoolIndex]}
                    giveTcpaPermission={this.giveTcpaPermission}
                    isTcpaDisclosureChecked={isTcpaDisclosureChecked}
                    selectedSchoolIndex={selectedSchoolIndex + 1}
                  />
                )}

              {!isSearchingMatches &&
              showNotFoundMessage &&
              (searchMatchesFailed ||
                (searchMatchesDone && allMatches.count < 1)) ? (
                <Box mt={2} align="right">
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={this.onSearchStageTwoButtonClicked}
                    id="step3-next-button"
                    fontSize="14px"
                    disabled={
                      widgetData.id === incompleteWidgetId ||
                      !isTcpaDisclosureChecked ||
                      !values.tcpaDisclosure
                    }
                    data-tf-element-role="submit"
                  >
                    {widgetData.id === incompleteWidgetId
                      ? "No match found"
                      : "Submit & Next"}
                  </CustomButton>
                </Box>
              ) : (
                <>
                  {isMobileBrowser() ? (
                    <Box mt={2} align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          !oneToOneTcpaStage && sharedSchools.length > 1
                            ? this.onNextStepButtonClicked
                            : this.submitLeadOnNextButtonClicked
                        }
                        disabled={
                          (!isSearchingMatches &&
                            !searchMatchesDone &&
                            !searchMatchesFailed) ||
                          selectedSchoolOptions.length === 0 ||
                          !isTcpaDisclosureChecked ||
                          !values.tcpaDisclosure
                        }
                        id="step3-next-button"
                        data-tf-element-role="submit"
                      >
                        Submit & Next
                        <KeyboardArrowRight />
                      </Button>
                      {oneToOneTcpaStage && !(selectedSchoolIndex === 0) && (
                        <PreviousButton
                          onClick={this.nextSelectedSchool}
                          id="step3-not-interested-button"
                          color="#c6c6c6"
                        >
                          I am not interested in this School
                        </PreviousButton>
                      )}
                    </Box>
                  ) : (
                    <Box mt={2} align="right">
                      {!oneToOneTcpaStage && (
                        <PreviousButton
                          onClick={this.onPreviousStepButtonClicked}
                          className={
                            isAllQuestionValidated && allMatches.count > 0
                              ? "disabled"
                              : ""
                          }
                          id="step3-previous-button"
                        >
                          Previous Step
                        </PreviousButton>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          !oneToOneTcpaStage && sharedSchools.length > 1
                            ? this.onNextStepButtonClicked
                            : this.submitLeadOnNextButtonClicked
                        }
                        disabled={
                          (!isSearchingMatches &&
                            !searchMatchesDone &&
                            !searchMatchesFailed) ||
                          selectedSchoolOptions.length === 0 ||
                          !isTcpaDisclosureChecked ||
                          !values.tcpaDisclosure
                        }
                        id="step3-next-button"
                        data-tf-element-role="submit"
                      >
                        Submit & Next
                        <KeyboardArrowRight />
                      </Button>

                      {oneToOneTcpaStage &&
                        !(
                          sharedSchools.length === 0 &&
                          selectedSchoolIndex === 0
                        ) && (
                          <PreviousButton
                            onClick={this.nextSelectedSchool}
                            id="step3-not-interested-button"
                          >
                            I am not interested in this School
                          </PreviousButton>
                        )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Form>
        </FormBodyContainer>
        <HiddenImage
          src={`${assetsURL}/loader-01.gif`}
          alt="hidden loader image"
        />
        <HiddenImage
          src={`${assetsURL}/loader-02.gif`}
          alt="hidden loader image"
        />
        
      </Fragment>
    );
  }
}

const StepThreeQuestions = withFormik({
  mapPropsToValues: ({ formData }) => ({
    ...formData
  }),
  validationSchema: schema
})(StepThreeForm);

const mapStateToProps = ({
  question,
  lead,
  location,
  match,
  widget
  // validation
}) => {
  return {
    formData: question.formData,
    currentStep: question.currentStep,
    allQuestions: question.allQuestions,
    stepCompleted: question.stepCompleted,
    leadData: lead.leadData,
    isCompletingLead: lead.isCompletingLead,
    completeLeadDone: lead.completeLeadDone,
    locationData: location.locationData,
    isSearchingMatches: match.isSearchingMatches,
    searchMatchesDone: match.searchMatchesDone,
    searchMatchesFailed: match.searchMatchesFailed,
    allMatches: match.allMatches,
    widgetData: widget.widgetData,
    isSubmittingMatches: match.isSubmittingMatches,
    submitMatchesDone: match.submitMatchesDone,
    selectedSchoolOptions: match.selectedSchoolOptions,
    submittedSchools: match.submittedSchools
  };
};

const mapActionsToProps = {
  goToPreviousStep: questionActions.goToPreviousStep,
  goToNextStep: questionActions.goToNextStep,
  goToStageTwoSearch: questionActions.goToStageTwoSearch,
  setCompletedSteps: questionActions.setCompletedSteps,
  updateLead: leadActions.updateLead,
  completeLead: leadActions.completeLead,
  searchMatchesV2: matchActions.searchMatchesV2,
  submitMatches: matchActions.submitMatches,
  resetMatchSearch: matchActions.resetMatchSearch,
  trackPixel: trackActions.trackPixel,
  trackSnapchatPixel: trackActions.trackSnapchatPixel,
  // searchClickOutsMatches: matchActions.searchClickOutsMatches,
  setSelectedSchoolOptions: matchActions.setSelectedSchoolOptions
};

export default connect(mapStateToProps, mapActionsToProps)(StepThreeQuestions);
