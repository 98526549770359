import React from "react";
import styled from "styled-components";
import { Field } from "formik";
import { Typography } from "@material-ui/core";
import TCPATextModal from "./TCPATextModal";

const FieldContainer = styled.div`
  label {
    display: block;
    margin: 20px 0px 5px 0px;
  }

  input[type="text"],
  input[type="email"] {
    padding: 8px 4px;
    width: calc(100% - 8px);
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: inset 0px 4px 0px #f7f7f7;
  }

  input[type="checkbox"] {
    height: 1em !important;
    transform: scale(1.5) !important;
    float: left;
    width: 16px;
    height: 16px;
    margin: 0px 5px 5px 0px !important;
    font-size: 24px;
  }

  input[type="checkbox"] + label {
    padding-left: 24px;
    font-size: 15px;
  }

  select {
    height: 30px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input,
  textarea,
  select {
    font-size: 100%;
  }

  input.error,
  select.error {
    border: 1px solid #ef4c4c;
    border-radius: 4px 4px 0px 0px;
  }

  .error-message {
    background-color: #ef4c4c;
    border-radius: 3px;
    color: #ffe7e7;
    font-size: 14px;
    margin: 5px 0px 0px 0px;
    padding: 4px 10px;
  }

  input[type="text"] + .error-message,
  input[type="email"] + .error-message {
    width: calc(100% - 18px);
  }
`;

const FormField = ({ label, trustedFormLabel, touched, error, ...props }) => (
  <FieldContainer
    {...(trustedFormLabel && { "data-tf-element-role": trustedFormLabel })}
  >
    {label && !props.hidden && props.type !== "checkbox" && (
      <label htmlFor={props.id || props.name}>{label}</label>
    )}
    <Field
      {...props}
      className={touched && error ? `error` : ``}
      id={props.id || props.name}
    />

    {label && props.type === "checkbox" && (
      <label htmlFor={props.id || props.name}>
        {label}{" "}
        {props.tcpaConcentText ? (
          <TCPATextModal
            schoolName={label}
            tcpaConcentText={props.tcpaConcentText}
          />
        ) : (
          ""
        )}
      </label>
    )}
    {touched && error && (
      <Typography className="error-message">{error}</Typography>
    )}
  </FieldContainer>
);

export default FormField;
